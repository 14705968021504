type BaseType = Readonly<Array<string|number>>;

export const base62: BaseType = Object.freeze([
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F',
  'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V',
  'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l',
  'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
])

export const baseDecimal: BaseType = Object.freeze(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'])

export const baseHexadecimal: BaseType = Object.freeze([
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'
])

export default class BaseConverter {
  static _isNegativeValueAllowed (base: BaseType): boolean {
    return Array.isArray(base) && !base.includes('-')
  }

  static encode (num: bigint | null, base: BaseType): string | null {
    if (num === null || !Array.isArray(base) || !base.length) {
      return null
    }
    let value = num
    const encoded = []
    const count = BigInt(base.length)

    if (count === 1n && num !== 0n) {
      return null
    }
    while (value >= count) {
      const i = value % count
      value = (value - i) / count
      encoded.push(base[Number(i)])
    }
    encoded.push(base[Number(value)])
    if (num < 0) {
      if (!this._isNegativeValueAllowed(base)) {
        return null
      }
      encoded.push('-')
    }
    return encoded.reduce((str, curr) => String(curr) + str, '')
  }

  static decode (value: string, base: BaseType): bigint | null {
    if (value === '' || !Array.isArray(base) || !base.length) {
      return null
    }
    const count = base.length
    const hash = base.reduce((hash, num, i) => { hash[num] = i; return hash }, {})

    let number = BigInt(0)
    let str = String(value)
    let isNegative = false

    if (str[0] === '-' && this._isNegativeValueAllowed(base)) {
      str = str.substr(1, str.length - 1)
      isNegative = true
    }

    let pow = 0
    while (str.length > 0) {
      const chr = str[str.length - 1]
      const num = hash[chr]
      if (typeof num !== 'number') {
        return null
      }
      str = str.substr(0, str.length - 1)
      number = number + BigInt(count) ** BigInt(pow) * BigInt(num)
      pow += 1
    }

    if (isNegative) {
      number = -number
    }

    return number
  }

  static convert (value: string, fromBase: BaseType, toBase: BaseType): string | null {
    const num = this.decode(value, fromBase)
    return this.encode(num, toBase)
  }
}
