import BaseConverter from './baseConverter'

export { parseUrlBoard, makeBoardUrlQuery, boardModePropsList } from './urlBoard'
export { parseBoardData } from './parseBoardData'
export { getOpeningRuleController, getGuideText, getInputModeDisabled, getInputModeVisible, getBranchModeDisabled } from './openingRule'

export function deepCopy<T> (obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}

export const sha256 = async (text: string) => {
  const uint8 = new TextEncoder().encode(text)
  const digest = await crypto.subtle.digest('SHA-256', uint8)
  return Array.from(new Uint8Array(digest)).map(v => v.toString(16).padStart(2, '0')).join('')
}

export function encodeObjectId (objectId: string) {
  return BaseConverter.convert(objectId, baseHexadecimal, base62)
}

export function decodeObjectId (encodedObjectId: string) {
  return BaseConverter.convert(encodedObjectId, base62, baseHexadecimal)
}

export function getGameResultText (result: GameResult, endNumber: string) {
  let text = gameResultList.find(gameResult => gameResult.value === result)?.text
  const splitEndNumber = endNumber.split('-')
  if (!text || splitEndNumber.length !== 2 || splitEndNumber[0].length === 0) { return '' }
  const color = splitEndNumber[0] === 'black' ? '黒' : (splitEndNumber[0] === 'white' ? '白' : '')
  text = text.replace(/\[number\]/g, splitEndNumber[1])
  text = text.replace(/\[last-move-color\]/g, color)
  text = text.replace(/\[next-move-color\]/g, splitEndNumber[0] === 'black' ? '白' : (splitEndNumber[0] === 'white' ? '黒' : ''))

  return text
}

export function getGameResultPoint (moves: MoveProps[], result :GameResult, endNumber: string, color: StoneColor) {
  const lastMoveColor = endNumber = endNumber.split('-')[0]
  if (!['black', 'white'].includes(lastMoveColor)) {
    return null
  }
  let swapCount = 0
  let currentColor = color
  moves.forEach(move => move.swap && swapCount++)
  if (swapCount % 2 === 1) {
    if (color === 'black') { currentColor = 'white' }
    if (color === 'white') { currentColor = 'black' }
  }
  switch (result) {
    case '-': return null
    case 'resign': return lastMoveColor === currentColor ? 1 : 0
    case 'draw': return 0.5
    case 'five': return lastMoveColor === currentColor ? 1 : 0
    case 'timeout': return lastMoveColor === currentColor ? 0 : 1
    case 'forbidden': return lastMoveColor === currentColor ? 0 : 1
    default: return null
  }
}

export function convertMovesToRenjuNetMoves (moves: MoveProps[], board: BoardProps) {
  return moves.map((move) => {
    if (move.number === undefined || move.x === undefined || move.y === undefined) {
      return ''
    }
    const x = renjuNetCoordinateX[move.x]
    const y = board.numberOfYLine - move.y
    return `${x}${y}`
  }).join('')
}
