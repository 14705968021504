export const defaultMarkStrings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export const defaultBoardState: BoardState = {
  moves: [{
    number: 0,
    color: '-',
    marks: [],
    markStrings: defaultMarkStrings
  }],
  history: [],
  mode: 'edit',
  type: 'game',
  inputMode: 'stone',
  branch: {
    enable: false,
    startNumber: undefined,
    tempHistory: []
  },
  answers: [],
  alternateStoneColor: 'black',
  board: {
    numberOfXLine: 15,
    numberOfYLine: 15,
    starPositions: [{ x: 3, y: 3 }, { x: 3, y: 11 }, { x: 7, y: 7 }, { x: 11, y: 3 }, { x: 11, y: 11 }],
    boxSize: 40,
    color: {
      background: '#ffd88b',
      star: '#695020',
      line: '#695020',
      coordinate: '#69502099',
      blackStoneBackground: '#222222',
      blackStoneLine: '#222222',
      blackStoneNumber: '#ffffff',
      whiteStoneBackground: '#ffffff',
      whiteStoneLine: '#222222',
      whiteStoneNumber: '#222222',
      mark: '#695020'
    },
    coordinateX: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S'],
    coordinateY: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
    visible: {
      number: true,
      coordinate: true
    }
  },
  game: {
    tournament: {
      renjuNetId: ''
    },
    title: '',
    date: null,
    round: '',
    openingRule: '-',
    endNumber: '-',
    result: '-',
    playerA: {
      renjuNetId: '',
      name: '',
      englishName: '',
      color: 'black',
      remainTime: undefined
    },
    playerB: {
      renjuNetId: '',
      name: '',
      englishName: '',
      color: 'white',
      remainTime: undefined
    }
  },
  puzzle: {
    title: '',
    turn: '-',
    type: '-'
  },
  dialogBoardImage: false,
  dialogBoardImport: false,
  dialogBoardExport: false,
  dialogBoardSettings: false,
  dialogConfirmation: false,
  dialogGameInformationSettings: false,
  dialogPuzzleInformationSettings: false,
  dialogShareBoardUrl: false,
  dialogPostScore: false,
  dialogRenjuBoard: false,
  toggleType: 'game'
}

export const renjuNetCoordinateX = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's']

export const emptyMoveProps: MoveProps = { number: 0, color: '-', marks: [], markStrings: defaultMarkStrings }

export const starPositionPropsList = [
  {
    numberOfXLine: 15,
    numberOfYLine: 15,
    starPositions: [{ x: 3, y: 3 }, { x: 3, y: 11 }, { x: 7, y: 7 }, { x: 11, y: 3 }, { x: 11, y: 11 }]
  },
  {
    numberOfXLine: 19,
    numberOfYLine: 19,
    starPositions: [{ x: 3, y: 3 }, { x: 3, y: 9 }, { x: 3, y: 15 }, { x: 9, y: 3 }, { x: 9, y: 9 }, { x: 9, y: 15 }, { x: 15, y: 3 }, { x: 15, y: 9 }, { x: 15, y: 15 }]
  }
]

export const modePropsList: { value: BoardMode, label: string }[] = [
  { value: 'edit', label: '編集' },
  { value: 'view', label: '閲覧' }
]

export const boardTypePropsList: { value: BoardType, label: string }[] = [
  { value: 'game', label: '棋譜' },
  { value: 'puzzle', label: '問題' }
]

export const inputModePropsList: { value: InputMode, label: string }[] = [
  { value: 'stone', label: '交互' },
  { value: 'stone_alternate', label: '交互' },
  { value: 'stone_black', label: '黒石' },
  { value: 'stone_white', label: '白石' },
  { value: 'mark', label: '記号' },
  { value: 'swap', label: '交換' },
  { value: 'offer_number', label: '題数' },
  { value: 'offer_moves', label: '提示' }
]

export const turnPropsList: { value: TurnType, label: string | null }[] = [
  { value: '-', label: null },
  { value: 'black', label: '黒先' },
  { value: 'white', label: '白先' }
]

export const openingRulePropsList: { value: OpeningRule, label: string, param: string | null, renjuNetId?: number }[] = [
  { value: '-', label: '-', param: null },
  { value: 'swap_1', label: '珠型交替五珠自由打ち', param: 'sw1' },
  { value: 'soosyrv_8', label: '四珠交替打ち', param: 's8', renjuNetId: 24 },
  { value: 'taraguchi_10', label: '五珠交替打ち', param: 't10', renjuNetId: 25 }
]

export const renjuSitePropsList: { value: RenjuSiteType, label: string | null }[] = [
  { value: 'renju_net', label: 'renju net' },
  { value: 'renju_note', label: 'renju note' },
  { value: 'gomoku_quest', label: '五目クエスト' }
]

export const gameResultList: { value: GameResult, label: string, text: string }[] = [
  { value: '-', label: '-', text: '-' },
  { value: 'resign', label: '投了', text: '[last-move-color][number]にて[next-move-color]投了' },
  { value: 'draw', label: '満局', text: '[last-move-color][number]にて満局' },
  { value: 'five', label: '五連', text: '[last-move-color][number]にて五連により[last-move-color]勝ち' },
  { value: 'forbidden', label: '禁手', text: '[last-move-color][number]にて[last-move-color]の禁手により[next-move-color]勝ち' },
  { value: 'timeout', label: '時間切れ', text: '[last-move-color][number]にて[next-move-color]の時間切れにより[last-move-color]勝ち' }
]

export const twitterThumbnailPropsList: { value: TwitterThumbnailType, param: string }[] = [
  { value: 'twitter_thumbnail_wide', param: 'w' },
  { value: 'twitter_thumbnail_square', param: 's' }
]

export const scoreStatusPropsList: { value: ScoreStatus | 'all', label: string, color: string, icon: IconName }[] = [
  { value: 'all', label: 'すべて', color: '', icon: '' },
  { value: 'draft', label: '下書き', color: 'blue-grey-lighten-1', icon: 'edit' },
  { value: 'private', label: '非公開', color: 'lime-darken-3', icon: 'edit' },
  { value: 'publish', label: '公開中', color: 'green-darken-1', icon: 'visibility' }
]

export const puzzleStatusPropsList: { value: PuzzleStatus | 'all', label: string, color: string, icon: IconName, compare?: 'lte' | 'gt', checkPublishDate?: (date: string) => boolean }[] = [
  { value: 'all', label: 'すべて', color: '', icon: '' },
  { value: 'draft', label: '下書き', color: 'blue-grey-lighten-1', icon: 'edit' },
  { value: 'private', label: '非公開', color: 'lime-darken-3', icon: 'edit' },
  { value: 'publish', label: '公開中', color: 'green-darken-2', icon: 'visibility', compare: 'lte', checkPublishDate: (date: string) => new Date() >= new Date(date) },
  { value: 'publish', label: '公開予定', color: 'amber-darken-4', icon: 'visibility', compare: 'gt', checkPublishDate: (date: string) => new Date() < new Date(date) }
]

export const noteStatusPropsList: { value: NoteStatus | 'all', label: string, color: string, icon: IconName, compare?: 'lte' | 'gt', checkPublishDate?: (date: string) => boolean }[] = [
  { value: 'all', label: 'すべて', color: '', icon: '' },
  { value: 'draft', label: '下書き', color: 'blue-grey-lighten-1', icon: 'edit' },
  { value: 'private', label: '非公開', color: 'lime-darken-3', icon: 'edit' },
  { value: 'publish', label: '公開中', color: 'green-darken-2', icon: 'visibility', compare: 'lte', checkPublishDate: (date: string) => new Date() >= new Date(date) },
  { value: 'publish', label: '公開予定', color: 'amber-darken-4', icon: 'visibility', compare: 'gt', checkPublishDate: (date: string) => new Date() < new Date(date) }
]

export const danKyuPropsList: { value: DanKyuType | null, label: string }[] = [
  { value: null, label: '-' },
  { value: '20k', label: '20級' },
  { value: '19k', label: '19級' },
  { value: '18k', label: '18級' },
  { value: '17k', label: '17級' },
  { value: '16k', label: '16級' },
  { value: '15k', label: '15級' },
  { value: '14k', label: '14級' },
  { value: '13k', label: '13級' },
  { value: '12k', label: '12級' },
  { value: '11k', label: '11級' },
  { value: '10k', label: '10級' },
  { value: '9k', label: '9級' },
  { value: '8k', label: '8級' },
  { value: '7k', label: '7級' },
  { value: '6k', label: '6級' },
  { value: '5k', label: '5級' },
  { value: '4k', label: '4級' },
  { value: '3k', label: '3級' },
  { value: '2k', label: '2級' },
  { value: '1k', label: '1級' },
  { value: '1d', label: '初段' },
  { value: '2d', label: '二段' },
  { value: '3d', label: '三段' },
  { value: '4d', label: '四段' },
  { value: '5d', label: '五段' },
  { value: '6d', label: '六段' },
  { value: '7d', label: '七段' },
  { value: '8d', label: '八段' },
  { value: '9d', label: '九段' }
]

export const countryPropsList: { value: CountryType | null, label: string }[] = [
  { value: null, label: '-' },
  { value: 'AD', label: 'アンドラ' },
  { value: 'AE', label: 'アラブ首長国連邦' },
  { value: 'AF', label: 'アフガニスタン' },
  { value: 'AG', label: 'アンティグア・バーブーダ' },
  { value: 'AI', label: 'アンギラ' },
  { value: 'AL', label: 'アルバニア' },
  { value: 'AM', label: 'アルメニア' },
  { value: 'AO', label: 'アンゴラ' },
  { value: 'AQ', label: '南極' },
  { value: 'AR', label: 'アルゼンチン' },
  { value: 'AS', label: 'アメリカ領サモア' },
  { value: 'AT', label: 'オーストリア' },
  { value: 'AU', label: 'オーストラリア' },
  { value: 'AW', label: 'アルバ' },
  { value: 'AZ', label: 'アゼルバイジャン' },
  { value: 'BA', label: 'ボスニア・ヘルツェゴビナ' },
  { value: 'BB', label: 'バルバドス' },
  { value: 'BD', label: 'バングラデシュ' },
  { value: 'BE', label: 'ベルギー' },
  { value: 'BF', label: 'ブルキナファソ' },
  { value: 'BG', label: 'ブルガリア' },
  { value: 'BH', label: 'バーレーン' },
  { value: 'BI', label: 'ブルンジ' },
  { value: 'BJ', label: 'ベナン' },
  { value: 'BL', label: 'セント・バーツ' },
  { value: 'BM', label: 'バミューダ諸島' },
  { value: 'BN', label: 'ブルネイ' },
  { value: 'BO', label: 'ボリビア' },
  { value: 'BR', label: 'ブラジル' },
  { value: 'BS', label: 'バハマ' },
  { value: 'BT', label: 'ブータン' },
  { value: 'BV', label: 'ブーベ島' },
  { value: 'BW', label: 'ボツワナ' },
  { value: 'BY', label: 'ベラルーシ' },
  { value: 'BZ', label: 'ベリーズ' },
  { value: 'CA', label: 'カナダ' },
  { value: 'CC', label: 'ココス (キーリング) 諸島' },
  { value: 'CD', label: 'コンゴ民主共和国' },
  { value: 'CF', label: '中央アフリカ共和国' },
  { value: 'CG', label: 'コンゴ共和国' },
  { value: 'CH', label: 'スイス' },
  { value: 'CI', label: 'コートジボワール' },
  { value: 'CK', label: 'クック諸島' },
  { value: 'CL', label: 'チリ' },
  { value: 'CM', label: 'カメルーン' },
  { value: 'CN', label: '中国' },
  { value: 'CO', label: 'コロンビア' },
  { value: 'CR', label: 'コスタリカ' },
  { value: 'CU', label: 'キューバ' },
  { value: 'CV', label: 'カーボベルデ' },
  { value: 'CW', label: 'キュラソー' },
  { value: 'CX', label: 'クリスマス島' },
  { value: 'CY', label: 'キプロス' },
  { value: 'CZ', label: 'チェコ共和国' },
  { value: 'DE', label: 'ドイツ' },
  { value: 'DJ', label: 'ジブチ' },
  { value: 'DK', label: 'デンマーク' },
  { value: 'DM', label: 'ドミニカ国' },
  { value: 'DO', label: 'ドミニカ共和国' },
  { value: 'DZ', label: 'アルジェリア' },
  { value: 'EC', label: 'エクアドル' },
  { value: 'EE', label: 'エストニア' },
  { value: 'EG', label: 'エジプト' },
  { value: 'EH', label: '西サハラ' },
  { value: 'ER', label: 'エリトリア' },
  { value: 'ES', label: 'スペイン' },
  { value: 'ET', label: 'エチオピア' },
  { value: 'FI', label: 'フィンランド' },
  { value: 'FJ', label: 'フィジー' },
  { value: 'FK', label: 'フォークランド諸島 (マルビナス諸島)' },
  { value: 'FM', label: 'ミクロネシア連邦' },
  { value: 'FO', label: 'フェロー諸島' },
  { value: 'FR', label: 'フランス' },
  { value: 'FX', label: 'フランス本土' },
  { value: 'GA', label: 'ガボン' },
  { value: 'GB', label: '英国' },
  { value: 'GD', label: 'グレナダ' },
  { value: 'GE', label: 'グルジア' },
  { value: 'GF', label: 'フランス領ギアナ' },
  { value: 'GG', label: 'ガーンジー' },
  { value: 'GH', label: 'ガーナ' },
  { value: 'GI', label: 'ジブラルタル' },
  { value: 'GL', label: 'グリーンランド' },
  { value: 'GM', label: 'ガンビア' },
  { value: 'GN', label: 'ギニア' },
  { value: 'GP', label: 'グアドループ' },
  { value: 'GQ', label: '赤道ギニア' },
  { value: 'GR', label: 'ギリシャ' },
  { value: 'GS', label: '南グルジアおよび諸島' },
  { value: 'GT', label: 'グアテマラ' },
  { value: 'GU', label: 'グアム' },
  { value: 'GW', label: 'ギニアビサウ' },
  { value: 'GY', label: 'ガイアナ' },
  { value: 'HK', label: '香港' },
  { value: 'HM', label: 'ハード島トマクドナルド諸島' },
  { value: 'HN', label: 'ホンジュラス' },
  { value: 'HR', label: 'クロアチア' },
  { value: 'HT', label: 'ハイチ' },
  { value: 'HU', label: 'ハンガリー' },
  { value: 'ID', label: 'インドネシア' },
  { value: 'IE', label: 'アイルランド' },
  { value: 'IL', label: 'イスラエル' },
  { value: 'IM', label: 'マン島' },
  { value: 'IN', label: 'インド' },
  { value: 'IO', label: '英領インド洋植民地' },
  { value: 'IQ', label: 'イラク' },
  { value: 'IR', label: 'イラン' },
  { value: 'IS', label: 'アイスランド' },
  { value: 'IT', label: 'イタリア' },
  { value: 'JE', label: 'ジャージー' },
  { value: 'JM', label: 'ジャマイカ' },
  { value: 'JO', label: 'ヨルダン' },
  { value: 'JP', label: '日本' },
  { value: 'KE', label: 'ケニア' },
  { value: 'KG', label: 'キルギス' },
  { value: 'KH', label: 'カンボジア' },
  { value: 'KI', label: 'キリバス' },
  { value: 'KM', label: 'コモロ' },
  { value: 'KN', label: 'セントクリストファー・ネイビス' },
  { value: 'KP', label: '北朝鮮' },
  { value: 'KR', label: '韓国' },
  { value: 'KW', label: 'クウェート' },
  { value: 'KY', label: 'ケイマン諸島' },
  { value: 'KZ', label: 'カザフスタン' },
  { value: 'LA', label: 'ラオス' },
  { value: 'LB', label: 'レバノン' },
  { value: 'LC', label: 'セントルシア' },
  { value: 'LI', label: 'リヒテンシュタイン' },
  { value: 'LK', label: 'スリランカ' },
  { value: 'LR', label: 'リベリア' },
  { value: 'LS', label: 'レソト' },
  { value: 'LT', label: 'リトアニア' },
  { value: 'LU', label: 'ルクセンブルク' },
  { value: 'LV', label: 'ラトビア' },
  { value: 'LY', label: 'リビア' },
  { value: 'MA', label: 'モロッコ' },
  { value: 'MC', label: 'モナコ' },
  { value: 'MD', label: 'モルドバ' },
  { value: 'ME', label: 'モンテネグロ' },
  { value: 'MF', label: 'セント・マーチン島' },
  { value: 'MG', label: 'マダガスカル' },
  { value: 'MH', label: 'マーシャル諸島' },
  { value: 'MK', label: 'マケドニア' },
  { value: 'ML', label: 'マリ' },
  { value: 'MM', label: 'ビルマ' },
  { value: 'MN', label: 'モンゴル' },
  { value: 'MO', label: 'マカオ' },
  { value: 'MP', label: '北マリアナ諸島' },
  { value: 'MQ', label: 'マルティニーク' },
  { value: 'MR', label: 'マーリタニア' },
  { value: 'MS', label: 'モントセラト' },
  { value: 'MT', label: 'マルタ' },
  { value: 'MU', label: 'モーリシャス' },
  { value: 'MV', label: 'モルジブ' },
  { value: 'MW', label: 'マラウィ' },
  { value: 'MX', label: 'メキシコ' },
  { value: 'MY', label: 'マレーシア' },
  { value: 'MZ', label: 'モザンビーク' },
  { value: 'NA', label: 'ナミビア' },
  { value: 'NC', label: 'ニューカレドニア' },
  { value: 'NE', label: 'ニジェール' },
  { value: 'NF', label: 'ノーフォーク島' },
  { value: 'NG', label: 'ナイジェリア' },
  { value: 'NI', label: 'ニカラグア' },
  { value: 'NL', label: 'オランダ' },
  { value: 'NO', label: 'ノルウェー' },
  { value: 'NP', label: 'ネパール' },
  { value: 'NR', label: 'ナウル' },
  { value: 'NU', label: 'ニウエ' },
  { value: 'NZ', label: 'ニュージーランド' },
  { value: 'OM', label: 'オマーン' },
  { value: 'PA', label: 'パナマ' },
  { value: 'PE', label: 'ペルー' },
  { value: 'PF', label: 'フランス領ポリネシア' },
  { value: 'PG', label: 'パプアニューギニア' },
  { value: 'PH', label: 'フィリピン' },
  { value: 'PK', label: 'パキスタン' },
  { value: 'PL', label: 'ポーランド' },
  { value: 'PM', label: 'サンピエール島とミクロン島' },
  { value: 'PN', label: 'ピトケアン島' },
  { value: 'PR', label: 'プエルトリコ' },
  { value: 'PS', label: 'ガザ地区' },
  { value: 'PS', label: 'ヨルダン川西岸' },
  { value: 'PT', label: 'ポルトガル' },
  { value: 'PW', label: 'パラオ' },
  { value: 'PY', label: 'パラグアイ' },
  { value: 'QA', label: 'カタール' },
  { value: 'RE', label: 'レユニオン' },
  { value: 'RO', label: 'ルーマニア' },
  { value: 'RS', label: 'セルビア' },
  { value: 'RU', label: 'ロシア' },
  { value: 'RW', label: 'ルワンダ' },
  { value: 'SA', label: 'サウジアラビア' },
  { value: 'SB', label: 'ソロモン諸島' },
  { value: 'SC', label: 'セイシェル' },
  { value: 'SD', label: 'スーダン' },
  { value: 'SE', label: 'スウェーデン' },
  { value: 'SG', label: 'シンガポール' },
  { value: 'SH', label: 'セントヘレナ、アセンション島、トリスタンダクーニャ' },
  { value: 'SI', label: 'スロベニア' },
  { value: 'SJ', label: 'スバールバル諸島' },
  { value: 'SK', label: 'スロバキア' },
  { value: 'SL', label: 'シエラレオネ' },
  { value: 'SM', label: 'サンマリノ' },
  { value: 'SN', label: 'セネガル' },
  { value: 'SO', label: 'ソマリア' },
  { value: 'SR', label: 'スリナム' },
  { value: 'SS', label: '南スーダン' },
  { value: 'ST', label: 'サントメプリンシペ' },
  { value: 'SV', label: 'エルサルバドル' },
  { value: 'SX', label: 'シント・マールテン' },
  { value: 'SY', label: 'シリア' },
  { value: 'SZ', label: 'スワジランド' },
  { value: 'TC', label: 'タークス・カイコス諸島' },
  { value: 'TD', label: 'チャド' },
  { value: 'TF', label: 'フランス領南方・南極地域' },
  { value: 'TG', label: 'トーゴ' },
  { value: 'TH', label: 'タイ' },
  { value: 'TJ', label: 'タジキスタン' },
  { value: 'TK', label: 'トケラウ' },
  { value: 'TL', label: '東チモール' },
  { value: 'TM', label: 'トルクメニスタン' },
  { value: 'TN', label: 'チュニジア' },
  { value: 'TO', label: 'トンガ' },
  { value: 'TR', label: 'トルコ' },
  { value: 'TT', label: 'トリニダード・ドバゴ' },
  { value: 'TV', label: 'ツバル' },
  { value: 'TW', label: '台湾' },
  { value: 'TZ', label: 'タンザニア' },
  { value: 'UA', label: 'ウクライナ' },
  { value: 'UG', label: 'ウガンダ' },
  { value: 'UM', label: '合衆国領有小離島' },
  { value: 'US', label: '米国' },
  { value: 'UY', label: 'ウルグアイ' },
  { value: 'UZ', label: 'ウズベキスタン' },
  { value: 'VA', label: 'バチカン市国' },
  { value: 'VC', label: 'セントビンセント・グレナディーン' },
  { value: 'VE', label: 'ベネズエラ' },
  { value: 'VG', label: '英領バージン諸島' },
  { value: 'VI', label: 'バージン諸島' },
  { value: 'VN', label: 'ベトナム' },
  { value: 'VU', label: 'バヌアツ' },
  { value: 'WF', label: 'ワリー・エ・フトゥーナ諸島' },
  { value: 'WS', label: 'サモア' },
  { value: 'XK', label: 'コソボ' },
  { value: 'YE', label: 'イエメン' },
  { value: 'YT', label: 'マヨット' },
  { value: 'ZA', label: '南アフリカ' },
  { value: 'ZM', label: 'ザンビア' },
  { value: 'ZW', label: 'ジンバブエ' }
]
